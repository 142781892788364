<template>
    <div class="zt-page-content">
        <div class="page-header" style="min-width:1600px;">审批历史</div>
        <div class="zt-block" style="height: calc(100% - 100px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="comp" label="企业名称">
                        <template slot-scope="scope">
                            <div>{{scope.row.comp.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label="审批项目">
                        <template slot-scope="scope">
                            <div v-if="scope.row.type=='join'">企业入规申请</div>
                            <div v-if="scope.row.type=='quit'">企业退规申请</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createdAt" label="申请日期">
                        <template slot-scope="scope">
                            <div>{{scope.row.createdAt | moment("YYYY/MM/DD")}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="info.area" label="工厂面积(m²)">
                        <template slot-scope="scope">
                            <div>{{scope.row.info.area}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="info" label="总产值(万元)">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.info.revenue}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="info" label="员工数量">
                        <template slot-scope="scope">
                            <div>{{scope.row.info.staffCount}}</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="address" label="申请状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state==1">已保存待提交</div>
                            <div v-if="scope.row.state==2">已提交待审核</div>
                            <div v-if="scope.row.state==3">审核不通过</div>
                            <div v-if="scope.row.state==4">审核通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="meter_number" label="操作">
                        <template slot-scope="scope">
                            <el-button @click="goto('/bureau/approve/detail/'+scope.row.id)" type="text">查看详情</el-button>
                            <el-button v-if="scope.row.state>2" @click="viewCheck(scope.row)" type="text">查看审批</el-button>
                            <el-button v-else @click="check(scope.row)" type="text">审批</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page.pageNumber" :page-size="queryOpt.page.pageCount" :total="total"></el-pagination>
            </div>
        </div>
        <el-dialog width="650px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <Check :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></Check>
        </el-dialog>
    </div>
</template>

<script>
    import { findItem } from '@/util';
    import { applyList } from '@/service/bureau';
    import Check from './Check'
    export default {
        components: {
            Check
        },
        data() {
            return {
                popVisible: false,
                popTitle: null,
                empOptions: [
                    { id: 1, name: '1-20人' }, 
                    { id: 2, name: '21-50人' }, 
                    { id: 3, name: '51-100人' }, 
                    { id: 4, name: '101-200人' }, 
                    { id: 5, name: '201-500人' }, 
                    { id: 6, name: '501-1000人' }, 
                    { id: 7, name: '1001人以上' }
                ],
                queryOpt:{
                    where:{
                        type:"join"
                    },
                    page:{
                        pageNumber:1,
                        pageCount:15,
                    },
                    order: [
                        ["createdAt","desc"]
                    ],
                },
                total:0,
                list:[],
                ruleForm:"",
            }
        },
        async created() {
            this.refreshItems();
        },
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            refreshItems() {
                console.log('refreshItems');
                applyList(this.queryOpt).then(rst => {
                    if (rst && rst.rows.length>0) {
                        this.list = rst.rows;
                        this.list.forEach((e)=>{
                            if (e && e.info.staffCount) {
                                e.info.staffCount = findItem(this.empOptions,e.info.staffCount).name;
                            }
                        })
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            check(item) {
                this.popTitle = "审批";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    isPass:false,
                    result:{
                        type:null,
                        isZd:false,
                        isBt:false,
                        batch:null,
                        reason:null,
                    }
                };
            },
            viewCheck(item) {
                this.popTitle = "查看审批";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    isPass:false,
                    result:item.result,
                };
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>

    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-dialog {
        min-width: 550px;
    }
    div>>> .el-dialog__body {
        background: #F5F5F5;
    }

</style>