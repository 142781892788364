import { 
    postToBureanServer,
    postToGovServer,
    postToFactoryServer,
    postToBaseInfo1Server,
    postToPropServer
} from './common';
/**
 * 统计局看板
 */
export async function bureauData(obj) {
    return await postToBureanServer('/dashboard', obj);
}
/**
 * 企业入规审核列表
 */
export async function applyList(obj) {
    return await postToGovServer('/admin/apply/list', obj);
}
/**
 * 企业入规申请审批
 */
export async function applyAudit(obj){
    return await postToGovServer(`/admin/apply/audit`,obj);
}
/**
 * 企业入规申请记录详情
 */
export async function getApplyId(id){
    return await postToGovServer(`/admin/apply/get/${id}`);
}
/**
 * @desc 获取数据看板产品统计信息
 * @return Promise
 */
export async function getProductStatistics(){
    return await postToFactoryServer(`/factory/statistics-product`,{});
}
/**
 * @desc 搜索筛选工厂的产品列表
 * @param data:object -请求参数
 *              cat_id	-类目id	
                style_no	-风格id	
                market_stat	-集市状态，1上架，2下架，3已终止/已过期/已删除	
                handbook_stat	-销售手册状态，1上架，2下架，3已终止/已过期/已删除	
                k	-搜索关键字	
                page	-页码	
                pagesize  -每页条数
 * @return Promise
 */
export async function getProductList(data){
    return await postToFactoryServer(`/factory/list-product`,data);
}
/**
 * @desc 搜索筛选工厂列表
 * @param data:object -请求参数
 *              zone_id	-所在区域ID	
                n_employee	-员工数量	
                reg_stat	-注册状态，1已注册，2未注册，0全部	
                cert_stat	-认证状态，1已认证，2未认证，0全部
                spec_stat	-入规状态，1已入规，2未入规，0全部	
                k	-搜索关键字	
                page	-当前页码	
                pagesize  -每页条数
 * @return Promise
 */
export async function getFactoryList(data){
    return await postToBaseInfo1Server(`/factory/list-factory`,data);
}
/**
 * 工厂的产品按集市订单之样式统计(admin前端)
 */
 export async function marketOrderNByStyle(obj){
    return await postToFactoryServer(`/factory/market-order-n-by-style`,obj);
}
/**
 * 查询金融备料调查问卷(admin前端)
 */
export async function surveyStatistics(obj){
    return await postToPropServer(`/survey/beiliao/admin/statistics`,obj);
}
/**
 * 查询金融备料调查问卷列表(admin前端)
 */
export async function surveyList(obj){
    return await postToPropServer(`/survey/beiliao/admin/list`,obj);
}




