<template>
    <div style="max-width: 450px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="110px" class="login-form">
            <el-form-item prop="result.type" label="是否允许入规">
                <div>
                    <el-radio-group :disabled="popTitle=='查看审批'" v-model="ruleForm.result.type">
                        <el-radio :label="0">入区规</el-radio>
                        <el-radio :label="1">入省规</el-radio>
                        <el-radio :label="2">未满足入规条件</el-radio>
                    </el-radio-group>
                </div>
                <div v-if="ruleForm.result.type==0 || ruleForm.result.type==1">
                    <el-checkbox :disabled="popTitle=='查看审批'" v-model="ruleForm.result.isZd">重点区域</el-checkbox>
                    <el-checkbox :disabled="popTitle=='查看审批'" v-model="ruleForm.result.isBt">抱团入规</el-checkbox>
                </div>
            </el-form-item>
            <el-form-item v-if="ruleForm.result.type==0 || ruleForm.result.type==1" prop="result.batch" label="入规批次">
                <el-select :disabled="popTitle=='查看审批'" v-model="ruleForm.result.batch" style="width:250px" placeholder="请选择">
                    <el-option v-for="item in timOptions" :key="item.id"
                    :label="item.label" :value="item.label"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="ruleForm.result.type==2" style="width:100%;position:relative;" prop="result.reason" label="原因">
                <el-input :disabled="popTitle=='查看审批'" type="textarea" v-model="ruleForm.result.reason" :rows="3" :maxlength="150" placeholder="请输入"></el-input>
                <div style="position:absolute;right:14px;bottom:0">
                    <span style="line-height: 25px;color:#B3B3B3;" class="font14-grey">{{ruleForm.result.reason && ruleForm.result.reason.length || 0}}/150</span>
                </div>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div v-if="popTitle=='查看审批'">
                    <el-button @click="hide()" style="width: 80px;" type="primary">确定</el-button>
                </div>
                <div v-else>
                    <el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { applyAudit } from '@/service/bureau';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    [`result.type`]: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
                    [`result.batch`]: [{ required: true, message: '请选择入规批次', trigger: 'blur' }],
                    [`result.reason`]: [{ required: true, message: '请输入原因', trigger: 'blur' }],
                },
                timOptions: [
                    { id: 1, label: '规上老企业' }, 
                    { id: 2, label: '2016年度' }, 
                    { id: 3, label: '2017年度' }, 
                    { id: 4, label: '2018年度' }, 
                    { id: 5, label: '2019年度' }, 
                    { id: 6, label: '2020年度' },
                ],
                ruleForm:{
                    id:null,
                    isPass:false,
                    result:{
                        type:null,
                        isZd:false,
                        isBt:false,
                        batch:null,
                        reason:null,
                    }
                },
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                if (this.ruleForm.result.type!=2) this.ruleForm.isPass=true;
                if (this.ruleForm.result.type==2) {
                    this.ruleForm.isPass=false;
                    this.ruleForm.isZd=false;
                    this.ruleForm.isBt=false;
                }
                applyAudit(this.ruleForm).then(rst => {
                    this.$emit("refreshItems");
                    this.$emit("hide");
                    this.$message.success("提交成功");
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style scoped>
    
</style>